import React from 'react';
import logo from './img/linkspark_logo.svg';
import user2 from './img/user2.jpg';
import user1 from './img/user1.jpg';
import './App.css';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import ReactStars from "react-rating-stars-component";
var QRCode = require('qrcode.react');


class ImageSRC extends React.Component {

  render() {
      const value = this.props.images
      return (
          <picture className="splash">
            <source media="(max-width: 719px)"
                    srcSet={`${value}-500w.webp`}
                    type="image/webp" />
            <source media="(min-width: 720px)"
                    srcSet={`${value}.webp`}
                    type="image/webp" />
            <source media="(min-width: 800px)"
                    srcSet={`${value}-100w.jpg,
                            ${value}-300w.jpg,
                            ${value}-500w.jpg,
                            ${value}-700w.jpg,
                            ${value}.jpg`}
                    type="image/jpeg" />
            <img src={`${value}.jpg`} alt={`${value}`} />
          </picture>    
      );
  }}
  

function Landing(props) {
  return (
      <div className="landing">
        <div className="textField">
          <p>Full Stack Web Development for you or your business.</p>
          <span onClick={props.link} className="button" >Let's Get Started.</span>
        </div>
        <ImageSRC images='splash/splash_1' />
      </div>
    );
}

function Landing2(props) {
  return (
      <div className="landing services">
        <ImageSRC images='splash/splash_2' />
        <div className="textField">
          <p>API development, database management, or front-end design for your next project.</p>
          <span onClick={props.link} className="button" >Solve Complex Problems.</span>
        </div>
        
      </div>
    );
}

const fiveStars = {
  size: 28,
  value: 5,
  edit: false
};

function Landing3() {
  return (
      <div className="landing about">
        <div className="scrollreviews">
          <div className="userIcon">
            <img src={user1} width="87px" height="87px" className="user" alt="Brent Taylor" />
          </div>
          <span>
          <div className="userNameStars">
            <h2>Brent Taylor</h2>
            <ReactStars {...fiveStars} />
          </div>
            
            <p>"Link Spark rebuilt our dated website from the ground up. We presented our needs and they built an API allowing us to easily communicate data to our ERP software. The process was well laid out and we recieved documentation for the new features. Updates and changes were swift and right on the mark."</p>
          </span>
        </div>
        <div className="scrollreviews">
          <div className="userIcon">
            <img src={user2} width="87px" height="87px" className="user" alt="Alice Ferguson" />
          </div>
          <span>
            <div className="userNameStars">
            <h2>Alice Ferguson</h2>
              <ReactStars {...fiveStars} />
            </div>
            
            <p>"We needed a solution for managing our social media presence and Link Spark created a tool to generate Instagram posts from our internal product database and post them automatically at a set interval.  This greatly improved the speed of our workflow and freed up time to focus on other aspects of social media management."</p>
          </span>
        </div>

        <div className="textField">
          <p>We work with clients of all shapes and sizes.  Custom tailored solutions built to your exact specifications.</p>
          
        </div>
        
      </div>
    );
}

function Switcher(props) {
  const active = props.active
  const link = props.onClickContact
  if (active === 1) {
    return <Landing2 link={link}/>;
  } else if (active === 2) {
    return <Landing3 />;
  }
  return <Landing link={link}/>;
}

function copyToClipboard(textToCopy) {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard api method'
      return navigator.clipboard.writeText(textToCopy);
  } else {
      // text area method
      let textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      // make the textarea out of viewport
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
          // here the magic happens
          document.execCommand('copy') ? res() : rej();
          textArea.remove();
      });
  }
}

const containerStyle = {
  width: '100%',
  height: '147px'
};

const center = {
  lat: 38.837,
  lng: -104.822
};
class Map extends React.Component {
  render() {
    return (
      <LoadScript
        googleMapsApiKey="AIzaSyCyu9Pp1VnW6SWiDjfqV2LkwdBe0oSGnb0"
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={10}
        >
          { /* Child components, such as markers, info windows, etc. */ }
          <></>
        </GoogleMap>
      </LoadScript>
    )
  }
}
class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      contact: false,
      phoneCopied: false,
      emailCopied: false,
      phone: '341-336-9083',
      email: 'info@linkspark.dev'
    };
  }

  set0() {
    this.setState({ page: 0 });
  };

  set1() {
    this.setState({ page: 1 });
  };

  set2() {
    this.setState({ page: 2 });
  };

  toggleContact() {
    console.log('toggle contact: ', this.state.contact)
    this.setState({ contact: !this.state.contact });
  };

  copyPhone() {
    this.setState({ phone: 'Copied to clipboard' });
    copyToClipboard("341-336-9083");
    this.setState({ phoneCopied: true});
    setTimeout(function() { //Start the timer
      this.setState({ phoneCopied: false});
    }.bind(this), 2000)
    setTimeout(function() { //Start the timer
      this.setState({ phone: '341-336-9083' });
    }.bind(this), 2000)
  };

  copyEmail() {
    this.setState({email: 'Copied to clipboard'});
    copyToClipboard("info@linkspark.dev");
    this.setState({ emailCopied: true});
    setTimeout(function() { //Start the timer
      this.setState({ emailCopied: false});
    }.bind(this), 2000)
    setTimeout(function() { //Start the timer
      this.setState({ email: 'info@linkspark.dev' });
    }.bind(this), 2000)
  };

  render() {
    return (
    <div>
      <div className={this.state.contact ? 'contactWrap': 'contactWrap hide'}>
        <div className="contact">
          <div className="contactHeader">
            <span>Contact</span>
            <p onClick={() => this.toggleContact()} className="contactClose">X</p>

          </div>
          <div className="contactBody">
            <div className="modalMap">
              <QRCode 
                  className="qrContact" 
                  size={147} 
                  value="BEGIN:VCARD
VERSION:3.0
N:Link Spark;
ORG:Link Spark LLC
EMAIL;TYPE=INTERNET:info@linkspark.dev
URL:https://linkspark.dev
TEL:341-336-9083
END:VCARD" 
              />
              <Map />
            </div>
            <table>
                <tbody>
                <tr>
                    <td>
                      <a href="tel:341-336-9083"><div className="icon phone"></div></a>
                    </td>
                    <td className={this.state.phoneCopied ? 'data copied': 'data'} onClick={() => {this.copyPhone()}}>
                      {this.state.phone}
                    </td>
                  </tr>
                  
                  <tr>
                    <td>
                      <a href="mailto:info@linkspark.dev"><div className="icon email"></div></a>
                    </td>
                    <td className={this.state.emailCopied ? 'data copied': 'data'} onClick={() => {this.copyEmail()}}>
                      {this.state.email}
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>
        </div>
        <div onClick={() => this.toggleContact()} className="contactBacking">
        </div>
      </div>
      <div className="container">
        <header>
          <img onClick={() => this.set0()} src={logo} className="logo" alt="logo" />
          <div className="links">
            <ul>
              <li className="mobileHome">
                <span onClick={() => this.set0()} >Home</span>
              </li>
              <li>
                <span onClick={() => this.set2()} >About</span>
              </li>
              <li>
                <span onClick={() => this.set1()} >Services</span>
              </li>
            </ul>
            <ul className="contactButton">
              <li>
                <span onClick={() => this.toggleContact()} className="button" >Contact Us</span>
              </li>
            </ul>
          </div>
        </header>

        <Switcher onClickContact={() => this.toggleContact()} active={this.state.page}/>

        <div className="copyright">
          <p>Copyright © 2021 Link Spark LLC. All&nbsp;Rights&nbsp;Reserved.</p>
        </div>

        
        
      </div>
      
    </div>
  )};
}

function App() {
  return (
    <Main />
  );
}

export default App;
